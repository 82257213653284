// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	font-family: $displayFont;
	text-transform: uppercase;
	font-weight: 400;
	font-size: rem(18px);

	@include breakpoint(large) {
		padding: 0;
		font-weight: 300;
	}
	
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint(large) {
		background: transparent;
		color: $primary;


	}
	
}
%buttonActive {
	// Link Active
	color: $dark;
	background-color: $border;

	@include breakpoint(large) {
		background: transparent;
		color: $alert;
	}
	
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";



// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	.maxWidth{

		@include breakpoint(large) {
			padding: 0 1rem;
			max-width: rem(1620px);
			margin: 0 auto;
		}
	}

	nav {
		@include ul {
			&.navi {
				li {
					border-top: 1px solid $border;

					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								content: none;
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

span{
	&.dasBuero,
	&.standorte{
		&.active{
			&:after{
				color: $light!important;

				@include breakpoint(large) {
					color: auto!important;
				}
				
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		nav {
			ul {
				&.navi {
					padding: 0.5rem 0;
					border-top: 1px solid $border;
					display: flex;
					align-items: center;
					
					@include breakpoint(giant) {
						width: auto;
						border: none;
						padding: 2.35rem 0;
					}
					
					li {
						border: none;

						&.hasSub {
							span{
								cursor: auto;
							}
							.sub {
								li{
									border-top: 1px solid $border;
									padding: 0.75rem 1rem;
								}
							}
						}

						&.start{
							display: none;

							@include breakpoint(huge) {
								display: inline-block;
							}
							
						}

						&.kontakt{
							a{
								border-radius: 3rem;
								background: $primary;
								color: $light;
								padding: 0.5rem 1.5rem;
								
								@include breakpoint(huge) {
									padding: 0.5rem 2rem;
								}
								
								&:hover,
								&.active{
									background: $dark;
									color: $light;
								}
							}
						}

						&.navBack {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $primary;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	p{
		font-family: $displayFont;
		font-weight: 300;

		span{
			font-weight: 400;
		}
	}
}


li{
	&.hasSub{
		&.active{
			span{
				&.standorte{
					&:after{
						color: $light!important;
					}
				}
			}
		}
	}
}