
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-top: rem(50px);
	
	@include breakpoint(large) {
		margin-top: rem(168px);
	}
	
	@include breakpoint(giant) {
		margin-top: rem(124px);
	}
	
	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}

	.claim{
		.pic{
			@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), false);
			background-size: cover;
		}
	}
}

.claim{
	.text{
		padding: 4rem 1rem;
		
		@include breakpoint(small) {
			padding: 5rem 1rem;
		}
		
		@include breakpoint(medium) {
			padding: 0 1.5rem 0 1rem;
		}
		
		p{
			font-family: $displayFont;
			font-weight: 300;

			&.small{
				color: $alert;
				font-size: rem(20px);
				margin-bottom: 0.5rem;
				line-height: 1.4em;

				@include breakpoint(tiny) {
					font-size: rem(24px);
					margin-bottom: 0;
				}

				@include breakpoint(small) {
					font-size: rem(22px);
				}
				
				@include breakpoint(large) {
					font-size: rem(26px);
				}
				
				@include breakpoint(giant) {
					font-size: rem(38px);
				}
				
				@include breakpoint(huge) {
					font-size: rem(46px);
				}
				
				@include breakpoint(full) {
					font-size: rem(42px);
				}

				@include breakpoint(cut) {
					font-size: rem(44px);
				}
				
			}

			&.usp{
				color: $dark;
				margin-bottom: 1.5rem;
				font-size: rem(31px);
				line-height: 1.3em;
				font-weight: 400;
				
				@include breakpoint(tiny) {
					font-size: rem(42px);
				}
				
				@include breakpoint(medium) {
					font-size: rem(36px);
				}
				
				@include breakpoint(large) {
					font-size: rem(46px);
				}
				
				@include breakpoint(giant) {
					font-size: rem(56px);
				}
				
				@include breakpoint(huge) {
					font-size: rem(60px);
					font-weight: 500;
				}
				
				br{
					@include breakpoint(tiny,max) {
						display: none;
					}
				}
			}
		}

		ul{
			margin-bottom: 2rem;
			
			@include breakpoint(large) {
				margin-bottom: 2.5rem;
			}
			
			li{
				display: block;
				background: transparent;
				padding: 0.5rem 0.5rem 0.5rem 2.25rem;
				position: relative;
				font-family: $displayFont;
				font-weight: 300;
				text-transform: uppercase;
				font-size: rem(20px);
				
				@include breakpoint(small) {
					display: inline-block;
					margin-right: 2rem;

					&:last-child{
						margin-right: 0;
					}
				}
				
				@include breakpoint(giant) {
					margin-right: 1.5rem;
				}
				
				@include breakpoint(huge) {
					font-size: rem(22px);
				}
				
				&:before{
					content: '';
					height: 29px;
					width: 29px;
					background: url(/images/layout/check.png) no-repeat center;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	.pic{
		height: 16rem;

		@include breakpoint(small) {
			height: 20rem;
		}
		
		@include breakpoint(medium) {
			height: 32rem;
		}
		
		@include breakpoint(large) {
			height: 34rem;
		}
		
		@include breakpoint(giant) {
			height: 36rem;
		}
		
		@include breakpoint(huge) {
			height: rem(690px);
		}

		@include breakpoint(full) {
			height: rem(720px);

			&.full-7{
				max-width: 100%!important;
			}
		}
	}
}

.brandBack{
	padding: 1rem 1rem 0.5rem 1rem;
	background: $light;

	@include breakpoint(large) {
		display: none;
	}
}

.branding {
	display: block;
	max-width: 197px;
	width: 100%;
	height: auto;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	
	@include breakpoint(large) {
		margin: 1.25rem auto 0.65rem;
	}
	
	@include breakpoint(giant) {
		float: left;
		margin: 1.65rem 3rem 1.15rem 0;
	}
	
	@include breakpoint(huge) {
		margin-right: 5rem;
	}
	
	@include breakpoint(full) {
		margin-right: 8rem;
	}
	
	@include breakpoint(cut) {
		margin-right: 10rem;
	}
	
	&.largeBlock{
		@include breakpoint(large,max) {
			display: none;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	&#content{
		padding: 4rem 0 3rem;

		@include breakpoint(large) {
			padding: 5rem 0 4rem;
		}
		
		@include breakpoint(huge) {
			padding: 6rem 0 5rem;
		}
		
		.firstSection{
			background: $medium;
			padding-top: 4rem;
			
			@include breakpoint(small) {
				padding-top: 6rem;
			}
			
			@include breakpoint(large) {
				padding: 7rem 0;
			}
			
			@include breakpoint(giant) {
				padding: 8rem 0;
			}
			
			@include breakpoint(huge) {
				padding: 9rem 0;
			}
			
			.col{
				padding: 0;

				&.pic{
					.my-slider-teaser{
						.col{
							padding: 0 0.15rem;

							a{
								height: 16rem;
								position: relative;
								background: $dark;
								display: block;
								
								@include breakpoint(tiny) {
									height: 20rem;
								}
								
								@include breakpoint(medium) {
									height: 24rem;

									&:after{
										content: '';
										height: 100%;
										width: 100%;
										position: absolute;
										left: 0;
										top: 0;
										background: $primary;
										z-index: 1;
										transition: 600ms all ease;
										opacity: 0;
									}
								}
								
								@include breakpoint(large) {
									height: 26rem;
								}
								
								@include breakpoint(giant) {
									height: 30rem;
								}
								
								@include breakpoint(huge) {
									height: rem(530px);
								}
								
								&.first{
									background: url(/images/index/bgTeas1.jpg) no-repeat bottom center / cover;
								}

								&.second{
									background: url(/images/index/bgTeas2.jpg) no-repeat bottom center / cover;
								}

								&.third{
									background: url(/images/index/bgTeas3.jpg) no-repeat bottom center / cover;
								}

								span{
									transition: 400ms all ease;
									font-family: $displayFont;
									position: absolute;
									bottom: 2rem;
									left: 1.5rem;
									font-weight: 300;
									color: $light;
									font-size: rem(22px);
									z-index: 2;

									@include breakpoint(tiny) {
										left: 2rem;
										font-size: rem(24px);
									}

									@include breakpoint(large) {
										font-size: rem(19px);
										left: 1.5rem;
									}

									@include breakpoint(giant) {
										font-size: rem(22px);
										left: 2rem;
										bottom: 3rem;
									}
									
									@include breakpoint(huge) {
										font-size: rem(27px);
									}
									
									@include breakpoint(full) {
										font-size: rem(30px);
									}
									
								}

								&:hover{
									span{
										bottom: 3rem;

										@include breakpoint(giant) {
											bottom: 4rem;
										}
										
									}

									@include breakpoint(medium) {
										&:after{
											opacity: 0.7;
										}
									}
									
								}
							}
						}
					}
				}

				&.text{
					padding: 4rem 1rem 2.25rem;

					@include breakpoint(small) {
						padding: 5rem 1rem 3.25rem;
					}
					
					@include breakpoint(large) {
						padding: 0 1rem 0 2rem;
					}
					
					@include breakpoint(huge) {
						padding-left: 3.5rem;
					}
					
					@include breakpoint(full) {
						padding-left: 4.5rem;
					}
					
				}
			}
		}

		.seperatorDiv{
			@include responsiveImage('index/bgSeperator.jpg', (tiny, medium), true);
			padding: 5rem 0;
			
			@include breakpoint(tiny) {
				background-position: left center;
			}
			
			@include breakpoint(small) {
				padding: 6rem 0;
			}
			
			@include breakpoint(large) {
				padding: 7rem 0;
			}
			
			@include breakpoint(giant) {
				padding: 8rem 0;
				background-position: 30% center;
			}
			
			@include breakpoint(huge) {
				padding: 9rem 0;
				background-position: left center;
			}
			
			&.lazyLoaded {
				background-size:cover
			}

			ul{
				margin-bottom: 2rem;
				
				@include breakpoint(huge) {
					margin-bottom: 3rem;
				}
				
				li{
					display: block;
					background: transparent;
					padding: 0.5rem 0.5rem 0.5rem 2.5rem;
					position: relative;
					font-family: $displayFont;
					font-weight: 300;
					text-transform: uppercase;
					font-size: rem(18px);
					color: $light;
					position: relative;
					border-bottom: 0;
					line-height: 1.4em;
					
					@include breakpoint(giant) {
						font-size: rem(20px);
					}
					
					&:before{
						content: ''!important;
						height: 29px;
						width: 29px;
						background: url(/images/layout/check.png) no-repeat center;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}

		.secondSection{

			@include breakpoint(large) {
				padding: 7rem 0;
			}
			
			@include breakpoint(giant) {
				padding: 8rem 0;
			}
			
			@include breakpoint(huge) {
				padding: 9rem 0;
			}
			
			.pic{
				@include responsiveImage('index/bgSecond.jpg', (tiny, medium), true);
				height: 16rem;
				
				@include breakpoint(small) {
					height: 20rem;
				}
				
				@include breakpoint(large) {
					height: 44rem;
				}
				
				@include breakpoint(giant) {
					height: 64rem;
				}
				
				
				&.lazyLoaded {
					background-size:cover
				}
			}

			.text{
				padding: 4rem 1rem 2.25rem;

				@include breakpoint(small) {
					padding: 5rem 1rem 3.25rem;
				}
				
				@include breakpoint(large) {
					padding: 0 1rem 0 2rem;
				}
				
				@include breakpoint(huge) {
					padding-left: 3.5rem;
				}
				
				@include breakpoint(full) {
					padding-left: 4.5rem;
				}
				
			}
		}

		.newsDiv{
			background: $blue;
			padding: 4rem 0;
			color: $light;

			h2,.h2{
				color: $light;

				span{
					color: $light;
				}
			}

			@include breakpoint(small) {
				padding: 5rem 0;
			}
			
			@include breakpoint(medium) {
				overflow: hidden;
				background: $blue url(/images/index/bgNews.jpg) no-repeat center right / cover;
				padding: 6rem 0;
			}

			@include breakpoint(large) {
				padding: 7rem 0;
			}
			
			@include breakpoint(huge) {
				padding: 5rem 0;
				background-size: auto;
			}
			
		}

		.thirdSection{
			padding: 4rem 0 2.25rem;

			@include breakpoint(small) {
				padding: 5rem  0 3.25rem;
			}
			
			@include breakpoint(large) {
				padding: 7rem 0 4.25rem;
			}
			
			@include breakpoint(giant) {
				padding: 8rem 0 5.25rem;
			}
			
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $medium;

	.footer{
		.claim{
			.text{
				a{
					&.btn{
						margin-bottom: 0.35rem;
						margin-right: 0.35rem;

						@include breakpoint(giant) {
							margin-right: 0.75rem;
						}
						
					}
				}

				p{
					&.phoneDiv{
						border-radius: 3rem;
						border: 2px solid $alert;
						color: $dark;
						font-weight: 300;
						display: block;
						text-align: center;
						padding: 0.695rem 1.5rem;
						
						@include breakpoint(small) {
							display: inline-block;
							padding: 0.695rem 2.5rem;
						}
						
						span{
							font-weight: 400;
						}
					}

					&.usp{

						@include breakpoint(large) {
							margin-bottom: 3rem;
						}
						
					}
				}
			}

			.pic{
				@include responsiveImage('layout/bgFooter.jpg', (tiny, medium), true);

				&.lazyLoaded {
					background-size:cover
				}
			}
		}
	}

	.contacts{
		background: $light;
		padding: 4rem 0 3rem;
		
		@include breakpoint(small) {
			padding: 5rem 0 4rem 1rem;
		}
		
		@include breakpoint(giant) {
			
		}
		
		.col{
			p{
				margin-bottom: 1rem;
				font-size: rem(16px);

				@include breakpoint(medium) {
					font-size: rem(18px);
				}
				
				@include breakpoint(giant) {
					font-size: rem(16px);
				}
				
				@include breakpoint(huge) {
					font-size: rem(16px);
				}
				
				@include breakpoint(full) {
					font-size: rem(18px);
				}
				
			}

			&.social{
				p{
					position: relative;
					padding-left: 3rem;

					&:before{
						content: '';
						height: 41px;
						width: 41px;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						background: url(/images/layout/facebook.png) no-repeat;
					}
				}
			}
		}
	}

	.naviFoot{
		background: $light;

		.naviAdd{
			text-align: center;
			
			@include breakpoint(small) {
				padding: 1.5rem 0;
				border-top: 1px solid $border;
			}
			
			li{
				font-family: $displayFont;
				border-top: 1px solid $border;
				text-transform: uppercase;
				font-weight: 300;
				
				@include breakpoint(small) {
					display: inline-block;
					border: none;
					margin: 0 0.65rem;
				}
				
				@include breakpoint(medium) {
					margin: 0 1.25rem;
				}
				
				a{
					display: block;
					padding: 0.75rem 0;
					color: $dark;
					
					@include breakpoint(small) {
						display: inline-block;
						padding: 0;
					}
					
					&.active{
						color: $alert;
					}

					&:hover{
						color: $primary;
					}
				}
			}
		}
	}
}

h1{
	&.ihk{
		position: relative;
		padding-left: 4rem;
		z-index: 1;
		margin-bottom: 5rem!important;

		&:before{
			content: '';
			height: 96px;
			width: 96px;
			position: absolute;
			left: 0;
			top: 0.9rem;
			background: url(/images/index/ihk.png) no-repeat center;
			z-index: -1;
		}
	}
}

body{
	&.cmsBackend{
		header,nav,footer{
			display: none;
		}
	}

	&.index{
		main{
			&#content{
				padding: 0;
			}
		}
	}
}