/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 26px;

$h1Size: 46px;

$h2Size: 32px;

$h3Size: 26px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: Open Sans, sans-serif;

$displayFont: "Oswald", sans-serif;

$iconFont: sans-serif;

// Project colors

$primary: #115ea3;
$secondary: #5b5e62;
$light: #fff;
$medium: #f0f0f0;
$dark: #3b3b3b;
$border: #eaeaea;
$alert: #b5b5b5;
$background: #f4f4f4;
$blue: #103959;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (5, 6, 7),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (6, 10),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (4, 5, 7, 8, 9),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 4, 7),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (5, 6, 7),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

