@include fontFaceV2(
    $fontName: "Oswald", 
    $fileName: "oswald-v31-latin", 
    $weights: ("400", "500", "300", "700"), 
    $types: all
);

@include fontFaceV2(
    $fontName: "Open Sans", 
    $fileName: "open-sans-v17-latin", 
    $weights: ("300", "300|italic", "400|italic", "400", "700", "700|italic"), 
    $types: all
);